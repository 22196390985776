import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  DetailContent,
  DetailContainer,
  Description,
  AddUpgradeContainer,
  UpgradeTextContainer,
  UpgradePrice,
  UpgradeNote,
  UpgradeText,
  AddUpgradeContent,
  UpgradePriceContainer,
  AddUpgradeDesktop,
  AddUpgradeMobile,
  UpgradeTextMobile,
  ArrowUpgrade,
  SlideUpgradeMobile,
  ArrowContainer
} from './UpgradeStyles';
import DecorationNavigation from '../../components/decorationNavigation/DecorationNavigation';
import TextImage from '../../components/textImage/TextImage';
import BaseButton from '../../components/buttons/BaseButton';
import ArrowRight from '../../assets/icons/arrow_right.svg';
import { GetUpgradeList, GetUpgradeDetail, SelectUpgrade, RemoveUpgrade, MngUpgrade } from '../../infra/requests/UpgradeRequests';
import { findNextUpgrade, findPrevUpgrade } from '../../infra/services/utils/PageUpgrade';
import { GetWedding, GetByTag } from '../../infra/requests/MyWeddingRequests';
import { SaveUpgrade, DeleteUpgrade } from '../../redux/Upgrade/upgrade.actions';
import ConfirmModal from '../../components/modals/ConfirmModal';
import Alert from '../../components/alert/Alert';
import { SaveWedding } from '../../redux/Wedding/wedding.actions';
import { SpinLoading } from "../../styles/BasicStyles";
import Slider from '../../components/slider/Slider';
import {
  editSection,
  processClosed
} from '../../infra/services/utils/BlockAnswers';

class UpgradeDetail extends Component {
  state = {
    upgradeDetail: undefined,
    nextUpgrade: undefined,
    prevUpgrade: undefined,
    openUpgradeMobile: false,
    upgradeText: undefined,
    showModal: false,
    loadingModal: false,
    titleModal: undefined,
    textModal: undefined,
    canEditSection: undefined,
    isProcessClosed: undefined,
    ready: false
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.body.style.overflowY = 'auto';

    this.getUpgradeDetail();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps?.match?.params?.id) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      document.body.style.overflowY = 'auto';

      this.getUpgradeDetail();
    }
  }

  getUpgradeDetail = async () => {
    const { weddingInfo, history } = this.props;
    let upgradeList = undefined, upgradeDetail = undefined, upgradeText = undefined;

    const canEditSection = editSection(weddingInfo.date, 'UPGRADE');
    // const isProcessClosed = processClosed(weddingInfo.date);
    const isProcessClosed = processClosed(weddingInfo.blockDate);

    let { data, success } = await GetUpgradeList();

    if (success) {
      upgradeList = data;
    }

    ({ data, success } = await GetUpgradeDetail(this.props.match.params.id));

    if (success) {
      upgradeDetail = data;
    }

    if (!upgradeDetail) {
      history.push('/404');
      return false;
    }

    const nextUpgrade = findNextUpgrade(upgradeList, this.props.match.params.id);
    const prevUpgrade = findPrevUpgrade(upgradeList, this.props.match.params.id);

    ({ data, success } = await GetByTag('UPGRADE'));

    if (success) {
      upgradeText = data;
    }

    this.setState({
      upgradeDetail,
      nextUpgrade,
      prevUpgrade,
      upgradeText,
      canEditSection,
      isProcessClosed,
      ready: true
    });
  };

  toggleUpgradeMobile = () => {
    this.setState({
      openUpgradeMobile: !this.state.openUpgradeMobile
    });
  }

  showModal = () => {
    const { weddingInfo, translate } = this.props;
    const { canEditSection, isProcessClosed, upgradeDetail } = this.state;

    if (isProcessClosed) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('PROCESS_CLOSED_TEXT')
      });
    }
    else if (!canEditSection) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
    else {
      let titleModal = undefined, textModal = undefined;

      if (weddingInfo.upgrade && weddingInfo.upgrade === upgradeDetail._id) {
        titleModal = translate('MODAL_TITLE_CANCEL_UPGRADE');
        textModal = translate('MODAL_TEXT_CANCEL_UPGRADE');
      }
      else {
        titleModal = translate('MODAL_TITLE_ADD_UPGRADE');
        textModal = translate('MODAL_TEXT_ADD_UPGRADE');
      }

      this.setState({
        titleModal,
        textModal,
        showModal: true
      });
    }
  }

  handleModalConfirm = async () => {
    this.setState({
      loadingModal: true
    });

    const { weddingInfo, dispatch, translate } = this.props;
    const { upgradeDetail } = this.state;

    let res = undefined, action = undefined;

    // if (weddingInfo?.upgrade && weddingInfo?.upgrade === upgradeDetail._id) {
    //   res = await RemoveUpgrade();
    //   action = 1;

    // }
    // else if (!weddingInfo?.upgrade || weddingInfo?.upgrade !== upgradeDetail._id) {
    //   res = await SelectUpgrade({ 'upgrade': upgradeDetail._id });
    //   action = 2;
    // }

    if (!weddingInfo?.upgrade || weddingInfo?.upgrade !== upgradeDetail._id) {
      res = await MngUpgrade(weddingInfo._id, { upgrade: upgradeDetail._id, userId: null });
    }

    if (res?.success) {
      const resWedding = await GetWedding();
      dispatch(SaveWedding(resWedding?.data));

      const upgrade = resWedding && resWedding.data && resWedding.data.wedding
        && resWedding.data.wedding.upgrade ? resWedding.data.wedding.upgrade : null;
      // Update or remove the upgrade name on header
      if (upgrade) {
        dispatch(SaveUpgrade(upgrade.name));
      } else dispatch(DeleteUpgrade());

      // if (action === 1) {
      //   dispatch(DeleteUpgrade());
      // }
      // else if (action === 2) {
      //   dispatch(SaveUpgrade(upgradeDetail.name));
      // }

      Alert.new({
        type: "success",
        title: translate("ALERT_SUCCESS_TITLE"),
        text: translate("ALERT_SUCCESS_TEXT")
      });
    } else {
      if(res?.message == 'BOTH_MAIN_DISHES_SELECTED') {
        Alert.new({
          type: "success",
          title: translate("ERROR"),
          text: translate("BOTH_MAIN_DISHES_SELECTED")
        });
      }
    }

    this.setState({
      showModal: false,
      loadingModal: false
    });
  };

  handleModalCancel = () => {
    this.setState({
      showModal: false,
      loadingModal: false
    });
  };

  render() {
    const { weddingInfo, menuOpen, translate, activeLanguage } = this.props;
    const { upgradeDetail, nextUpgrade, prevUpgrade, openUpgradeMobile, upgradeText, showModal, loadingModal, titleModal, textModal, ready } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        {
          showModal &&
          <ConfirmModal
            loading={loadingModal}
            title={titleModal}
            text={textModal}
            upgrade={upgradeDetail}
            handleConfirm={this.handleModalConfirm}
            handleCancel={this.handleModalCancel}
          />
        }
        <DetailContent>
          <DecorationNavigation
            returnUrl='/upgrade'
            mainCategory={translate('UPGRADE')}
            currentQuestion={upgradeDetail.name[activeLanguage?.code]}
            currentQuestionUrl={`/upgrade/${upgradeDetail._id}`}
            nextQuestion={nextUpgrade?.name[activeLanguage?.code]}
            nextQuestionUrl={`/upgrade/${nextUpgrade?._id}`}
            prevQuestion={prevUpgrade?.name[activeLanguage?.code]}
            prevQuestionUrl={`/upgrade/${prevUpgrade?._id}`}
            color={upgradeDetail.textColor ? upgradeDetail.textColor : '#816653'}
            upgradeSection={true}
          />
          <DetailContainer>
            <Description color={upgradeDetail.textColor} dangerouslySetInnerHTML={{ __html: upgradeDetail.description && upgradeDetail.description[activeLanguage?.code] }} />
          </DetailContainer>
          {
            upgradeDetail.gallery?.length > 0 &&
            <Slider gallery={upgradeDetail.gallery} />
          }
          {
            upgradeDetail.sections?.length > 0 &&
            upgradeDetail.sections.map((section, index) =>
              <TextImage
                key={index}
                type={section.type === 'text_only' ? 2 : 1}
                direction={section.type === 'text_left' ? 1 : 2}
                title=''
                text1={section.text && section.text[activeLanguage?.code]}
                text2={section.extra && section.extra[activeLanguage?.code]}
                color={upgradeDetail.textColor ? upgradeDetail.textColor : '#816653'}
                image={section.image?.url}
                imgColor={section.image?.color}
                bgColor={section.background ? section.background : '#ffffff'}
              />
            )
          }
        </DetailContent>
        <AddUpgradeContainer>
          <AddUpgradeContent menuOpen={menuOpen}>
            <AddUpgradeDesktop>
              <UpgradeTextContainer>
                <UpgradePriceContainer>
                  <UpgradeText>
                    {
                      upgradeDetail.price > 0 ?
                        translate('PRICE_PAX')
                        :
                        translate('NO_PRICE_UPGRADE')
                    }
                  </UpgradeText>
                  {
                    upgradeDetail.price > 0 &&
                    <UpgradePrice>
                      &nbsp;{upgradeDetail.price}€
                    </UpgradePrice>
                  }
                </UpgradePriceContainer>
                <UpgradeNote>
                  {upgradeText.information && upgradeText.information[activeLanguage?.code]}
                </UpgradeNote>
              </UpgradeTextContainer>
              <BaseButton
                align="initial"
                variant="raised"
                htmlType="button"
                type="primaryMedium"
                text={weddingInfo.upgrade && weddingInfo.upgrade === upgradeDetail._id ? translate("CANCEL_UPGRADE") : translate("ADD_UPGRADE")}
                bgColor={!weddingInfo.upgrade || weddingInfo.upgrade !== upgradeDetail._id ? 'primaryColor' : ''}
                onClick={this.showModal}
              />
            </AddUpgradeDesktop>
            <AddUpgradeMobile onClick={() => this.toggleUpgradeMobile()}>
              <UpgradeTextMobile>
                {weddingInfo.upgrade ? translate("CANCEL_UPGRADE") : translate("ADD_UPGRADE")}
              </UpgradeTextMobile>
              <ArrowUpgrade src={ArrowRight} />
            </AddUpgradeMobile>
          </AddUpgradeContent>
        </AddUpgradeContainer>
        <SlideUpgradeMobile open={openUpgradeMobile}>
          <ArrowContainer>
            <ArrowUpgrade
              src={ArrowRight}
              onClick={() => this.toggleUpgradeMobile()}
              open={true}
            />
          </ArrowContainer>
          <UpgradePriceContainer>
            <UpgradeText>
              {
                upgradeDetail.price > 0 ?
                  translate('PRICE_PAX')
                  :
                  translate('NO_PRICE_UPGRADE')
              }
            </UpgradeText>
            {
              upgradeDetail.price > 0 &&
              <UpgradePrice>
                &nbsp;{upgradeDetail.price}€
              </UpgradePrice>
            }
          </UpgradePriceContainer>
          <UpgradeNote>
            {upgradeText.information && upgradeText.information[activeLanguage?.code]}
          </UpgradeNote>
          <BaseButton
            align="initial"
            variant="raised"
            htmlType="button"
            type="primaryMedium"
            text={weddingInfo.upgrade && weddingInfo.upgrade === upgradeDetail._id ? translate("CANCEL_UPGRADE") : translate("ADD_UPGRADE")}
            bgColor={!weddingInfo.upgrade || weddingInfo.upgrade !== upgradeDetail._id ? 'primaryColor' : ''}
            onClick={this.showModal}
          />
        </SlideUpgradeMobile>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  menuOpen: state.menu,
  weddingInfo: state.wedding.wedding
});

export default connect(mapStateToProps)(withLocalize(withRouter(UpgradeDetail)));