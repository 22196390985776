import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { device } from '../../styles/Responsive';

export const ModalContainer = styled.div`
  
`;

export const ModalContent = styled(Modal)`
  &.ant-modal {
    width: 100% !important;
    max-width: 1480px;
    padding: 0px 40px;
    top: unset !important;

    @media ${device.tablet} {
      padding: 0px 15px;
    }

    @media ${device.mobileL} {
      padding: 0px 10px;
    }
  }

  .ant-modal-header {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .ant-modal-title, .ant-modal-close-x {
    color: #FFFFFF;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    padding: 0px !important;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 16px 24px;

  @media ${device.laptop} {
    padding: 14px 20px;
  }

  @media ${device.tablet} {
    padding: 12px 15px;
  }
`;

export const HeaderTitle = styled.div`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;
  color: #FFFFFF;
  padding-right: 10px;

  @media ${device.desktop} {
    font-size: 22px;
    line-height: 34px;
  }

  @media ${device.laptopL} {
    font-size: 20px;
    line-height: 32px;
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 28px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const HeaderIcon = styled.div`
  cursor: pointer;
  transition: opacity 0.5s linear;

  &:hover {
    opacity: 0.5;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color ? color : '#ece8e2'};
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const CarouselButton = styled(Button)`
  ${p => p?.right
    ? `
    right: 0px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    `
    : p?.left
      ? `
      left: 0px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      `
      : null}
  z-index: 99;
  
  background-color: rgba(64, 64, 64, 0.9) !important;
  color: white !important;
  border: unset !important;
  font-size: 18px !important;
  font-weight: bolder !important;
  padding: 0px 8px !important;
  height: 32px !important;
  width: 32px !important;
  display: flex !important;
  align-content: center;
  align-items: center;
  justify-content: center;
`;