import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2, titleH3 } from '../../styles/TextSizes';
import { Form } from "antd";

export const ReserveContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 100px;
  margin: auto;
  margin-bottom: 40px;

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  @media ${device.tablet} {
    padding: 40px 20px;
  }

  @media ${device.mobileL} {
    padding: 30px 10px;
    margin-bottom: 60px;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ first }) => (first ? '1rem' : '3rem')};
  margin-bottom: 8px;

  .datepicker {
    width: 150px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: ${p => p?.agendaPage ? 'row-reverse' : 'row'};
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 30px;

  @media ${device.desktop} {
    margin-bottom: 20px;
  }

  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`;

export const Title = styled(titleH2)`
  text-align: left;
  width: auto;
`;

export const Subtitle = styled.div`
  text-align: left;
  color: #bc9224;
  font-size: 19px;
  font-weight: 600;
  line-height: 31px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;

export const SectionTitle = styled(titleH3)`
  text-align: left;
  padding-top: 105px;

  @media ${device.desktopL} {
    padding-top: 70px;
  }

  @media ${device.desktop} {
    padding-top: 50px;
  }
`;

export const SectionSubtitle = styled.div`
  text-align: left;
  padding-top: 15px;
  white-space: pre-line;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;

export const PricePolicy = styled.div`
  height: 50px;
  display: flex;
  flex-flow: row-reverse;
  align-items: end;

  .anticon {
    color: #faad14;
  }

  @media (max-width: 576px) {
    height: auto;
    flex-flow: row;
    align-items: center;
    margin-top: 10px;
  }
`;

export const FilterActionButton = styled.div`
  height: 56px;
  display: flex;
  flex-flow: row-reverse;
  align-items: end;

  @media (max-width: 576px) {
    height: auto;
    flex-flow: row;
    align-items: center;
    margin-top: 10px;
  }
`;