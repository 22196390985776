import axios from "axios";
import { auth_token_key } from "./LocalStorageKeys";

const REACT_APP_API = process.env.REACT_APP_API;

const mergeCommonHeaders = rest => {
  const authToken = localStorage.getItem(auth_token_key);

  return {
    ...rest,
    Authorization: `Bearer ${authToken}`
  };
};

const client = axios.create({
  baseURL: REACT_APP_API,
  responseType: "json"
});

client.interceptors.request.use(request => {
  const headers = mergeCommonHeaders(request.headers.common);
  request.headers.common = headers;
  return request;
});

client.setupInterceptors = store => {
  client.interceptors.response.use(
    success => {
      return success.data;
    },
    error => {
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message === 'Error: No User Found' || error.response.data.message === 'Error: User Not Found') {
          error.response.data.message = 'USER_NOT_FOUND';
        }
        else if (error.response.data.message === 'Error: Invalid Password') {
          error.response.data.message = 'INVALID_PASSWORD';
        }
        else if (error.response.data.message === 'Error: ACCOUNT_ALREADY_REGISTERED') {
          error.response.data.message = 'ACCOUNT_ALREADY_REGISTERED';
        }
        else if (error.response.data.message === 'Error: INVALID_REGISTER_REQUEST') {
          error.response.data.message = 'INVALID_REGISTER_REQUEST';
        }
        else if (error.response.data.message === 'Error: Please define a password first') {
          error.response.data.message = 'MUST_DEFINE_PASSWORD';
        }
        else if (error.response.data.message === 'Error: Recover: Please define a password first') {
          error.response.data.message = 'RECOVER_MUST_DEFINE_PASSWORD';
        }
        else if (error.response.data.message === 'SERVER_OPTION_NOT_AVAILABLE') {
          error.response.data.message = 'WEDDING_OPTION_NOT_AVAILABLE';
        }
        else if (error.response.data.message === 'NOT_AUTHORIZED') {
          error.response.data.message = 'NOT_AUTHORIZED';
        }
        else if (error.response.data.message === 'NOT_AUTHORIZED_REROUTE') {
          error.response.data.message = 'NOT_AUTHORIZED_REROUTE';
        }
        else if (error.response.data.message === 'NO_ACCESS_WEDDING') {
          error.response.data.message = 'NO_ACCESS_WEDDING';
        }
        else if (error.response.data.message === 'ERROR_NO_RESOURCES') {
          error.response.data.message = 'ERROR_NO_RESOURCES';
        }
        else if (error.response.data.message === 'ERROR_DATE_FREE') {
          error.response.data.message = 'ERROR_DATE_FREE';
        }
        else if (error.response.data.message === 'VISITS_BLOCKED') {
          error.response.data.message = 'VISITS_BLOCKED';
        }
        else if (error.response.data.message === 'FIRST_VISIT_DONE') {
          error.response.data.message = 'VISITS_BLOCKED';
        }
        else if (error.response.data.message === 'NO_RESERVES_OR_INTEREST') {
          error.response.data.message = 'NO_RESERVES_OR_INTEREST';
        }
        else if (error.response.data.message === 'MEETINGS_BLOCKED') {
          error.response.data.message = 'MEETINGS_BLOCKED';
        }
        else if (error.response.data.message === 'ERROR_SCHEDULE_DATE_UNAVAILABLE') {
          error.response.data.message = 'UNAVAILABLE_DATE';
        }
        else if (error.response.data.message === 'ERROR_SERVICE_OCUPIED') {
          error.response.data.message = 'ERROR_SERVICE_OCUPIED';
        }
        else if (error.response.data.message === 'COUPLE_TABLE_REQUIRED') {
          error.response.data.message = 'COUPLE_TABLE_REQUIRED';
        }
        else if (error.response.data.message === 'ROOM_PLAN_COMPLETED') {
          error.response.data.message = 'ROOM_PLAN_COMPLETED';
        }
        else if (error.response.data.message === 'ROOM_PLAN_APPROVED') {
          error.response.data.message = 'ROOM_PLAN_APPROVED';
        }
        else if (error.response.data.message === 'ROOM_PLAN_BLOCKED') {
          error.response.data.message = 'ROOM_PLAN_BLOCKED';
        }
        else if (error.response.data.message === 'OLD_ROOM_PLAN_VERSION') {
          error.response.data.message = 'OLD_ROOM_PLAN_VERSION';
        }
        else if (error.response.data.message === 'COUPLE_TABLE_REQUIRED') {
          error.response.data.message = 'COUPLE_TABLE_REQUIRED';
        }
        else if (error.response.data.message === 'OPTION_BLOCKED_FOR_COUPLE') {
          error.response.data.message = 'OPTION_BLOCKED_FOR_COUPLE';
        }
        else if (error.response.data.message === 'COUPLE_TABLE_EMPTY') {
          error.response.data.message = 'COUPLE_TABLE_EMPTY';
        }
        else if (error.response.data.message === 'UPGRADE_BLOCKED_BOTH_MAIN_DISHES') {
          error.response.data.message = 'UPGRADE_BLOCKED_BOTH_MAIN_DISHES';
        }
        else if (error.response.data.message === 'BOTH_MAIN_DISHES_SELECTED') {
          error.response.data.message = 'BOTH_MAIN_DISHES_SELECTED';
        }
        else if (error.response.data.message === 'ERROR_REORDER_NOT_AVAILABLE') {
          error.response.data.message = 'ERROR_REORDER_NOT_AVAILABLE';
        }
        else if (error.response.data.message === 'ERROR_CANT_REORDER') {
          error.response.data.message = 'ERROR_CANT_REORDER';
        }
        else if (error.response.data.message === 'ERROR_REORDER_RESERVES') {
          error.response.data.message = 'ERROR_REORDER_RESERVES';
        }
        else {
          error.response.data.message = 'GENERIC_ERROR';
        }
      }

      if (error && error.response && error.response.status === 403) {
        localStorage.clear();
        // eslint-disable-next-line no-restricted-globals
        location.replace("/login");
      }

      return error.response
        ? { ...error.response.data, success: false }
        : { success: false };
    }
  );
};

export default client;