import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { initialize } from 'redux-form';
import { Row, Col, Button, Icon, Modal, Menu, Tooltip } from 'antd';
import { SpinLoading } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import { Toolbar, RoomEditor, RoomEditorContainer, RoomPlanBg, RoomPlanContainer } from './RoomPlanStyles';
import Alerts from '../../components/alert/Alert';
import BaseButton from '../../components/buttons/BaseButton';
// import SectionModal from '../ChoicesPage/SectionModal';
import { findPageByID, findPageByTag, findQuestionByID } from '../../infra/services/utils/PageInfo';
import { findNextChapter, findPrevChapter } from '../../infra/services/utils/PageNavigation';
import { blockedSubmitGuestList, editSection, processClosed, roomPlanClosed } from '../../infra/services/utils/BlockAnswers';
import LeavePageBlocker from '../../components/pageBlocker/LeavePageBlocker';
import ProgressBar from '../../components/progressBar/ProgressBar';
import DecorationNavigation from '../../components/decorationNavigation/DecorationNavigation';
import { Description, DetailContainer } from '../decoration/PageDetailStyles';
import { TransformToFormData } from '../../infra/services/formdata/TransformToFormData';
import { CheckUpdateCoupleTable, GetCoupleTable, GetFoodResctrictions, GetOurGuestsEditor, GetTableTypes, NotifyChangeRoomPlan, SaveRoomPlanEditor, SaveRoomPlanType, SubmitRoomPlan } from '../../infra/requests/GuestsRequests';
import { correctResponseValues } from '../../components/optionCard/OptionUtils';
import { AnswerQuestion } from '../../infra/requests/MyWeddingRequests';
import { updateQuestionAction } from '../../redux/Wedding/wedding.actions';
import XpertGoTextInput from '../../components/inputs/XpertGoTextInput';
import SaveButton from '../../components/buttons/SaveButton';
import XpertGoSelectInput from '../../components/inputs/XpertGoSelectInput';
import { inGuestMapTest } from '../../infra/services/utils/Helpers';
import SectionModal from '../decoration/SectionModal';
import { GetTranslationsRoomPlan } from '../../infra/requests/BaseRequests';
import { checkCoupleTableAnswer, removeNotExistingOptions } from '../../infra/services/utils/Options';

const { confirm } = Modal;

const sectionTag = "GUESTS";
const chapterId = "65afd1a7b42ba97e4a0db4b3";
const returnURL = "/guests";

export const RoomPlanTypes = {
  NORMAL: 'NORMAL',
  XL: 'XL',
}

export const roomPlanOptions = [
  { _id: RoomPlanTypes.NORMAL, name: 'Normal' },
  { _id: RoomPlanTypes.XL, name: 'XL' }
];


export const zoomOptions = [
  { _id: 50, name: '50%' },
  { _id: 80, name: '80%' },
  { _id: 100, name: '100%' },
  { _id: 150, name: '150%' }
];

class RoomPlanEditor extends Component {
  timeout = 0;
  roomEditor = null;

  state = {
    ready: false,
    loading: false,
    blocked: false,

    roomPlanBg: '',
    loadingBg: false,

    // zoom: 100,

    roomPlanReady: false,
    blockReroute: true,
    checkedChanges: false,

    chapter: undefined,
    nextChapter: undefined,
    prevChapter: undefined,
    roomPlan: undefined,
    isProcessClosed: undefined,
    ourGuest: {},
    answerSeatId: false,

    tables: [],
    objectTypes: [],
    foodRestrictions: [],

    saving: false,

    blockedSubmit: false,
    submiting: false,

    coupleTableQuestion: {},
    showModal: false,
    savingCoupleTable: false
  };

  componentDidMount = async () => {
    const { activeLanguage, history, translate } = this.props;
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    if (!inGuestMapTest()) return history.goBack();

    this.roomEditor = new window.RoomEditor('editor', 'pt');
    this.roomEditor.translationSystem.setActiveLanguage(activeLanguage?.code || 'pt');
    this.roomEditor.mouseManager.setChangeCoupleEvent(() => {
      this.openModal();
    });
    this.roomEditor.mouseManager.onGuestChangeError(() => {
      return Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('ERROR_CHANGE_GUESTS')
      });
    });
    // Warning of tables with extra cost
    // this.roomEditor.onExtraCostChanged(() => {
    //   return Alerts.new({
    //     type: 'warning',
    //     title: translate('ATTENTION'),
    //     text: translate('ACTIVATED_TABLE_EXTRA_COST')
    //   });
    // });

    this.getQuestionsInfo();
    this.getTranslations();
    this.getInformation();
    this.getCoupleTableQuestion();
  };

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps?.id) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflowY = "auto";

      this.getQuestionsInfo();
    }

    if (this.props?.activeLanguage?.code !== prevProps?.activeLanguage?.code) {
      if (this.roomEditor) this.roomEditor.translationSystem.setActiveLanguage(this.props?.activeLanguage?.code || 'pt');
    }
  }

  componentWillUnmount() {
    if (this.roomEditor) {
      this.roomEditor.destroy();
    }
  }

  getQuestionsInfo = () => {
    const { structureInfo, wedding } = this.props;

    this.setState({ ready: false });
    const { chapters } = findPageByTag(structureInfo, sectionTag);
    const chapter = findPageByID(chapters, chapterId);
    const nextChapter = findNextChapter(
      structureInfo,
      chapter.section,
      chapters,
      chapter._id
    );
    const prevChapter = findPrevChapter(
      structureInfo,
      chapter.section,
      chapters,
      chapter._id
    );
    // const isProcessClosed = processClosed(weddingInfo.date);
    const isProcessClosed = processClosed(wedding.blockDate);

    this.setState({
      chapter,
      nextChapter,
      prevChapter,
      isProcessClosed,
      ready: true
    });
  }

  getTranslations = async () => {
    const { data } = await GetTranslationsRoomPlan();
    if (this.roomEditor) this.roomEditor.setTranslations(data);
  }

  getInformation = async () => {
    const { translate } = this.props;
    const { structureInfo, wedding, weddingDate, activeLanguage } = this.props;

    this.setState({
      loading: true,
      roomPlanReady: false
    });

    const dataRoomEditor = {
      roomPlan: {
        imageSrc: null,
        x: 0,
        y: 0,
        scale: 0
      },
      kitchenNotes: '',
      tables: [],
      foodRestrictions: []
    };

    const sectionGuests = structureInfo.find(s => s.tag === 'GUESTS');
    const sectionDecor = structureInfo.find(s => s.tag === 'DECORATION');
    const chapterTables = sectionDecor?.chapters.find(f => f._id === '5e0ddbbfc8bb4f27117dc68d');
    const question = chapterTables.questions.find(f => f?._id === '5e18723686fdaa6d7aeedd91');
    const { data } = await GetOurGuestsEditor();
    const ourGuest = data?.ourGuest || {};
    let answerSeatId = data?.answerSeatId;

    if (this.roomEditor) {
      this.roomEditor.setWeddingPlace(wedding?.wedding_place);
    }

    // Set data for room editor
    dataRoomEditor.roomPlan = {
      imageSrc: `${process.env.REACT_APP_IMAGES_URL}${data?.ourGuest?.bgRoomPlan?._id}`,
      x: data?.ourGuest?.roomPlanPosition?.x || 0,
      y: data?.ourGuest?.roomPlanPosition?.y || 0,
      scale: data?.ourGuest?.roomPlanPosition?.scale || 0,
      horizontalCenterOfRoom: data?.ourGuest?.roomPlanHorizontalCenterOfRoom || 0,
      constraintPoints: data?.ourGuest?.roomPlanConstraintPoints || [],
      zones: data?.ourGuest?.roomPlanZones || [],
      spaceBetweenTables: data?.spaceBetweenTables || 0,
    };
    dataRoomEditor.kitchenNotes = data?.ourGuest?.kitchenNotes || '';
    dataRoomEditor.tables = data?.tables || [];

    // Table types
    const resultObjects = await GetTableTypes();
    if (resultObjects?.success) {
      this.setState({ objectTypes: resultObjects?.data || [] });
      // const coupleTable = resultObjects?.data?.filter(f => f?.coupleTable);
      const guestsTable = resultObjects?.data?.filter(f => !f?.coupleTable);

      if (this.roomEditor) {
        // this.roomEditor.activeCoupleTables(coupleTable.map(m => m?.tableClass));
        this.roomEditor.activeGuestTables(guestsTable.map(m => m?.tableClass));
      }
    } else {
      return Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('ERROR_LOADING_DATA')
      });
    }

    // Food restrictions
    const resultFoodRestrictions = await GetFoodResctrictions();
    if (resultFoodRestrictions?.success) {
      const data = resultFoodRestrictions?.data || [];
      dataRoomEditor.foodRestrictions = data;
      this.setState({ foodRestrictions: data });
    } else {
      return Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('ERROR_LOADING_DATA')
      });
    }

    if (dataRoomEditor.tables?.length > 0) {
      dataRoomEditor.tables = dataRoomEditor.tables.map(m => ({ ...m, tableType: m?.tableClass }));
    }
    if (this.roomEditor) this.roomEditor.deserializeEditor(dataRoomEditor);

    const blocked = roomPlanClosed(weddingDate) && !ourGuest?.roomPlanActive;
    this.setState({
      ready: true,
      sectionGuests,
      sectionDecor,
      coupleTableQuestion: question,
      ourGuest,
      answerSeatId,
      tables: data?.tables || [],
      blocked,
      blockedSubmit: blockedSubmitGuestList(weddingDate),
      loading: false
    }, () => {
      setTimeout(() => {
        if (this.roomEditor) {
          if (ourGuest?.validatedRoomPlan || blocked) this.roomEditor.setMode("READ_ONLY");
          else this.roomEditor.setMode("COUPLE");
        }

        if (answerSeatId) {
          if (this.roomEditor) this.roomEditor.setGuestsMode('SEAT');
        } else {
          if (this.roomEditor) this.roomEditor.setGuestsMode('TABLE');
        }

        this.setState({ roomPlanReady: true });
      }, 1500);
    });
  };

  getCoupleTableQuestion = () => {
    const { translate, dispatch } = this.props;
    const { structureInfo, weddingDate, blockDate } = this.props;

    const tag = 'DECORATION';
    const questionId = '5e18723686fdaa6d7aeedd91';

    const { chapters } = findPageByTag(structureInfo, tag);
    const question = findQuestionByID(chapters, questionId);

    if (!question) {
      Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('ERROR_LOADING_DATA')
      });
    }

    const canEditSection = editSection(weddingDate, tag);
    // const isProcessClosed = processClosed(weddingDate);
    const isProcessClosed = processClosed(blockDate);

    dispatch(initialize("section_form", {}));

    if (question?.answer) {
      dispatch(
        initialize("section_form", {
          ...question?.answer,
          question_obs: question?.answer?.observations !== "",
        })
      );
    }

    this.setState({
      coupleTableQuestion: question,
      canEditSection,
      isProcessClosed,
    });
  }

  confirmNotifyChangeRoomPlan = () => {
    const { translate } = this.props;

    confirm({
      title: translate('REACHED_TABLE_LIMIT'),
      content: translate('REACHED_TABLE_LIMIT_CONTENT'),
      okText: translate('INCREASE_ROOM_PLAN'),
      cancelText: translate('CANCEL'),
      onOk: () => this.notifyChangeRoomPlan(),
      onCancel: () => this.getInformation()
    });
  }

  notifyChangeRoomPlan = async () => {
    const { translate } = this.props;

    const result = await NotifyChangeRoomPlan();

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: translate('REQUEST_INCREASE_ROOM_PLAN')
      });
      this.getInformation();
    } else {
      this.getInformation();
    }
  }

  checkHasChangesToSave = () => {
    const { roomPlanReady, blockReroute } = this.state;

    let hasChanges = false;
    if (this.roomEditor && roomPlanReady) {
      hasChanges = this.roomEditor?.hasChanges();
    } else {
      hasChanges = false;
    }

    if (hasChanges !== blockReroute) {
      this.setState({
        blockReroute: hasChanges,
        checkedChanges: hasChanges ? true : false,
      });
    }

    return hasChanges;
  };


  saveRoomPlan = async () => {
    const { translate } = this.props;
    const { ourGuest } = this.state;
    this.setState({
      saving: true,
      loading: true
    });

    if (this.roomEditor) {
      const values = this.roomEditor.serializeEditor();
      const data = {
        ...values,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      };
      const result = await SaveRoomPlanEditor(data);

      if (result?.success) {
        Alerts.new({
          type: 'success',
          title: translate('SUCCESS'),
          text: translate('ROOM_PLAN_SAVED')
        });
        this.setState({
          saving: false,
          loading: false,
          ourGuest: result?.data?.ourGuest || {},
          tables: result?.data?.tables || []
        }, () => {
          if (result?.data?.notifyChangePlan) this.confirmNotifyChangeRoomPlan();
          else this.getInformation();
        });
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(result?.message)
        });
        this.setState({
          saving: false,
          loading: false
        }, () => {
          if (result?.message == 'NOT_AUTHORIZED' || result?.message == 'OLD_ROOM_PLAN_VERSION'
            || result?.message == 'ROOM_PLAN_BLOCKED') {
            this.getInformation();
          }
        });
      }
    }
  }

  confirmSubmitRoomPlan = async () => {
    const { translate } = this.props;

    confirm({
      title: translate('MARK_AS_COMPLETED_QUESTION'),
      content: (<div>
        <p>{translate('MARK_AS_COMPLETED_CONTENT')}</p>
      </div>
      ),
      okText: translate('MARK_AS_COMPLETED'),
      cancelText: translate('CANCEL'),
      onOk: () => {
        this.submitRoomPlan();
      },
      onCancel: () => null,
    });
  }

  submitRoomPlan = async () => {
    const { translate } = this.props;
    const { ourGuest } = this.props;
    this.setState({
      saving: true,
      submiting: true,
      loading: true
    });
    if (this.roomEditor) {
      // Check if room plan is valid
      // const valid = this.roomEditor?.valid();
      // console.warn('valid', valid);
      // if (valid?.status == 'INVALID') {
      //   Alerts.new({
      //     type: 'warning',
      //     title: translate('ATTENTION'),
      //     text: translate('ERROR_INVALID_ROOM_PLAN')
      //   });
      //   this.setState({
      //     saving: false,
      //     loading: false,
      //     submiting: false,
      //   });
      //   return;
      // }

      const values = this.roomEditor.serializeEditor();
      const data = {
        ...values,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      };
      const resultSave = await SaveRoomPlanEditor(data);

      if (resultSave?.success) {
        this.setState({
          saving: false,
          ourGuest: resultSave?.data?.ourGuest || {},
          tables: resultSave?.data?.tables || []
        });

        const resultSubmit = await SubmitRoomPlan();

        if (resultSubmit?.success) {
          Alerts.new({
            type: 'success',
            title: translate('SUCCESS'),
            text: translate('ROOM_PLAN_COMPLETED')
          });
          this.setState({
            submiting: false,
          }, () => this.getInformation());
        } else {
          Alerts.new({
            type: 'error',
            title: translate('ERROR'),
            text: translate(resultSubmit?.message)
          });
          this.setState({
            submiting: false,
          }, () => this.getInformation());
        }
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(resultSave?.message)
        });
        this.setState({
          saving: false,
          loading: false
        }, () => {
          if (resultSave?.message == 'NOT_AUTHORIZED' || resultSave?.message == 'OLD_ROOM_PLAN_VERSION'
            || resultSave?.message == 'ROOM_PLAN_BLOCKED') {
            this.getInformation();
          }
        });
      }
    }

  }

  // Couple table 
  openModal = async () => {
    const { translate } = this.props;
    const { ourGuest } = this.state;
    this.setState({
      saving: true,
      loading: true
    });

    if (this.roomEditor) {
      const values = this.roomEditor.serializeEditor();
      const data = {
        ...values,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      };
      const result = await SaveRoomPlanEditor(data);

      if (result?.success) {
        Alerts.new({
          type: 'success',
          title: translate('SUCCESS'),
          text: translate('ROOM_PLAN_SAVED')
        });
        this.setState({
          saving: false,
          loading: false,
          ourGuest: result?.data?.ourGuest || {},
          tables: result?.data?.tables || []
        }, async () => {
          await this.getCoupleTableQuestion();
          this.setState({
            showModal: true,
            saving: false,
            loading: false
          });
        });
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(result?.message)
        });
        this.setState({
          saving: false,
          loading: false
        }, () => {
          if (result?.message == 'NOT_AUTHORIZED' || result?.message == 'OLD_ROOM_PLAN_VERSION'
            || result?.message == 'ROOM_PLAN_BLOCKED') {
            this.getInformation();
          }
        });
      }
    }
  };

  confirmSubmitModal = async (values) => {
    const { translate, wedding } = this.props;
    const { coupleTableQuestion } = this.state;

    const data = checkCoupleTableAnswer(values, coupleTableQuestion, wedding.wedding_place);
    const resultCheck = await CheckUpdateCoupleTable(data);

    if (resultCheck?.success) {
      if (resultCheck?.data?.showWarning) {
        const ovalLWarning = resultCheck?.data?.showOvalLWarning
          ? resultCheck?.data?.ovalLWarning == 'TO_REMOVE_TABLES_BY_OVALL'
            ? translate('TO_REMOVE_TABLES_BY_OVALL')?.replace('#tables#', resultCheck?.data?.tablesToRemove)
            : resultCheck?.data?.ovalLWarning == 'TO_RESTORE_TABLES_BY_OVALL'
              ? translate('TO_RESTORE_TABLES_BY_OVALL')?.replace('#tables#', resultCheck?.data?.tablesToRemove)
              : null
          : null;

        if (resultCheck?.data?.showOvalLWarning && resultCheck?.data?.tablesToRemoveFilled) {
          this.setState({ showModal: false }, () => {
            Modal.warning({
              title: translate('ATTENTION'),
              content: translate('COUPLE_REMOVE_WITH_OVALL_BLOCKED'),
            });
          })
          return;
        }

        confirm({
          title: translate('UPDATE_COUPLE_TABLE_QUESTION'),
          content: (<div>
            <p>{translate('UPDATE_COUPLE_TABLE_CONTENT')}</p>
            {resultCheck?.data?.showOvalLWarning && ovalLWarning && <p><b>{ovalLWarning}</b></p>}
          </div>
          ),
          okText: translate('CHANGE_TABLE'),
          cancelText: translate('CANCEL'),
          onOk: () => {
            this.saveModal(values);
          },
          onCancel: () => null,
        });
      } else this.saveModal(values);
    } else {
      Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate(resultCheck.message)
      })
      return;
    }

  }

  saveModal = async (values) => {
    const { dispatch, translate, wedding } = this.props;
    const { coupleTableQuestion, objectTypes } = this.state;
    try {
      this.setState({ savingCoupleTable: true });
      values = removeNotExistingOptions(values, coupleTableQuestion, wedding?.wedding_place);
      values = correctResponseValues(values);
      const payload = TransformToFormData({ ...values, question: coupleTableQuestion._id });

      const result = await AnswerQuestion(payload);

      if (result.success) {
        const resultCouple = await GetCoupleTable();

        if (resultCouple?.success) {
          const coupleTable = resultCouple?.data?.tableType?.tableClass;
          if (coupleTable) this.roomEditor.mouseManager.changeCoupleTable(coupleTable);
        }

        dispatch(updateQuestionAction('DECORATION', result.data));
        this.setState({
          savingCoupleTable: false,
          showModal: false,
        }, () => this.saveRoomPlan());
      } else if (!result.success && result?.message === "WEDDING_OPTION_NOT_AVAILABLE") {
        Alerts.new({
          type: "",
          title: translate(result.message),
          text: ''
        });
      }
      this.setState({ savingCoupleTable: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const { history } = this.props;
    const { translate, activeLanguage } = this.props;
    const { wedding, weddingDate } = this.props;
    const { ready, loading, saving, submiting } = this.state;
    const { isProcessClosed, canEditSection, blocked, blockedSubmit } = this.state;
    const { ourGuest } = this.state;
    const { chapter, nextChapter, prevChapter } = this.state;
    const { showModal, coupleTableQuestion, savingCoupleTable } = this.state;
    const { blockReroute, checkedChanges } = this.state;

    // if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <LeavePageBlocker
          when={(inGuestMapTest() && !ourGuest?.validatedRoomPlan && !blocked && blockReroute)}
          checkedChanges={checkedChanges}
          onAttemptedRouteChange={this.checkHasChangesToSave}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        {!ready && <SpinLoading />}
        <div style={{ display: !ready ? 'none' : 'block' }}>
          {ready && <DecorationNavigation
            returnUrl={returnURL}
            mainCategoryId={chapterId}
            weddingDate={weddingDate}
            mainCategory={chapter.name[activeLanguage?.code]}
            currentQuestion={chapter.name[activeLanguage?.code]}
            currentQuestionUrl={`${returnURL}/${chapterId}`}
            nextQuestion={nextChapter?.name[activeLanguage?.code]}
            nextQuestionUrl={`${returnURL}/${nextChapter?._id}`}
            prevQuestion={prevChapter?.name[activeLanguage?.code]}
            prevQuestionUrl={`${returnURL}/${prevChapter?._id}`}
          />}
          <RoomPlanContainer>
            {/* {chapter?.description && (
              <Description
                dangerouslySetInnerHTML={{
                  __html:
                    chapter.description &&
                    chapter.description[activeLanguage?.code],
                }}
              />
            )} */}
            <div style={{ width: '100%' }}>
              <Row gutter={12} style={{ width: '100%' }}>
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                  <Toolbar>
                    {/* <div className='toolbar-input' style={{ width: 200 }}>
                      <XpertGoTextInput
                        input={{
                          value: search,
                          onChange: event => this.onInputChange('search', event.target.value)
                        }}
                        prefix={<Icon type={searching ? 'loading' : "search"} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        meta={{ valid: true }}
                        noLabel={true}
                        search={true}
                        disabled={blocked}
                        type="text"
                        placeholder={translate('SEARCH_GUEST')}
                      />
                    </div> */}
                    {/* <div className='toolbar-input' style={{ width: 170 }}>
                  <XpertGoSelectInput
                    // label="Tamanho:"
                    horizontal={true}
                    allowClear={false}
                    placeholder="Escolher tamanho"
                    data={roomPlanOptions}
                    disabled={changingPlan}
                    input={{
                      value: ourGuest?.roomPlanType,
                      onChange: value => this.confirmChangeRoomPlan(value)
                    }}
                    costumeLabel={this.costumeSelectRoomPlan}
                    meta={{ valid: true }}
                  />
                </div> */}
                    {/* <div className='zoom-icons toolbar-input'>
                      <Icon type="zoom-out" />
                      <Icon type="zoom-in" />
                    </div>
                    <div className='toolbar-input' style={{ width: 75 }}>
                      <XpertGoSelectInput
                        noBorder={true}
                        allowClear={false}
                        placeholder={translate('CHOOSE_ZOOM')}
                        data={zoomOptions}
                        disabled={loading || ourGuest?.validatedRoomPlan || blocked}
                        translatable={false}
                        input={{
                          value: zoom,
                          onChange: value => this.changeZoom(value)
                        }}
                        meta={{ valid: true }}
                      />
                    </div> */}
                  </Toolbar>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                  <Toolbar right={true}>
                    <div className='toolbar-input'>
                      {blockedSubmit
                        ? <Tooltip title={translate('BLOCKED_BTN_SUBMIT_GUESTS')}>
                          <Button
                            type='primary'
                            disabled={blockedSubmit || blocked || ourGuest?.submittedRoomPlan || ourGuest?.validatedRoomPlan || loading || saving}
                            onClick={this.confirmSubmitRoomPlan}>
                            {submiting ? <Icon type="loading" /> : <i className="fa-solid fa-file-circle-check"></i>}
                            <span style={{ marginLeft: 4 }}>{translate('MARK_AS_COMPLETED')}</span>
                          </Button>
                        </Tooltip>
                        : <Button
                          type='primary'
                          disabled={blockedSubmit || blocked || ourGuest?.submittedRoomPlan || ourGuest?.validatedRoomPlan || loading || saving}
                          onClick={this.confirmSubmitRoomPlan}>
                          {submiting ? <Icon type="loading" /> : <i className="fa-solid fa-file-circle-check"></i>}
                          <span style={{ marginLeft: 4 }}>{translate('MARK_AS_COMPLETED')}</span>
                        </Button>}
                    </div>
                    <div className='toolbar-input'>
                      {(!ourGuest?.validatedRoomPlan && !blocked) ? <BaseButton
                        type={'dark'}
                        icon={'save'}
                        text={translate("SAVE")}
                        disabled={blocked || loading || submiting}
                        loading={saving}
                        onClick={this.saveRoomPlan}
                      />
                        : <BaseButton
                          type={'dark'}
                          // icon={'arrow-right'}
                          text={translate("CONTINUE")}
                          disabled={loading || saving || submiting}
                          onClick={() => history.push(`${returnURL}/${nextChapter?._id}`)}
                        />}
                    </div>
                  </Toolbar>
                </Col>
              </Row>
              <Row gutter={12} style={{ marginTop: 10, width: '100%' }}>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                  <RoomEditorContainer>
                    {(loading || !ready) && <div className='backdrop'>
                      <SpinLoading />
                    </div>}
                    <RoomEditor id="editor"></RoomEditor>
                  </RoomEditorContainer>
                </Col>
              </Row>
              {/* {!isProcessClosed && (
              <SaveButton
              htmlType="button"
                text={translate("SAVE_CONTINUE")}
                textMobile={translate("SAVE")}
                withBackground={true}
                loading={loading}
              />
            )}
            {isProcessClosed && (
              <SaveButton
                htmlType="button"
                text={translate("CONTINUE")}
                textMobile={translate("CONTINUE")}
                withBackground={true}
                onClick={() => history.push(`${returnURL}/${nextChapter?._id}`)}
              />
            )} */}
            </div>
          </RoomPlanContainer>
        </div>

        {showModal &&
          <SectionModal
            open={showModal}
            loading={savingCoupleTable}
            question={coupleTableQuestion}
            canEditSection={canEditSection}
            isProcessClosed={isProcessClosed}
            onSubmit={this.confirmSubmitModal}
            closeModal={() => this.setState({ showModal: false })}
            question={coupleTableQuestion}
            translate={translate}
            activeLanguage={activeLanguage}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  structureInfo: state.wedding.structure,
  wedding: state.wedding.wedding,
  weddingDate: state.wedding.wedding.date,
  configs: state.info.configs,
  blockDate: state.wedding.wedding.blockDate,
});

const mapActionToProps = (dispatch) =>
  bindActionCreators({ updateQuestionAction, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(
  withLocalize(withRouter(RoomPlanEditor))
);
